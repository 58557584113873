<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput  size="sm" v-model="mts_no" placeholder="MTS No."/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="origin_id"
            placeholder="Origin"
          > 
          </v-select> 
        </CCol>
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="destination_id"
            placeholder="Destination"
          > 
          </v-select> 
        </CCol> 
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="3">
          <v-select 
            label="customer_name" 
            :options="customerList.data"
            :reduce="item => item.id"
            v-model="dept_id"
            placeholder="Dept/Proj"
          > 
          </v-select> 
        </CCol>
        <CCol lg="3">
          <CInput size="sm" v-model="ref_no" placeholder="Ref No."/>  
        </CCol>
        <CCol lg="3">
          
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            mts_no: "",
            origin_id: "",
            destination_id: "",
            dept_id: "",
            ref_no: "", 
            customerList: {
            	data: []
            }, 
          }
        },
      mounted() {
        this.getCustomer(); 
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
            mts_no: this.mts_no,
            origin_id: this.origin_id,
            destination_id: this.destination_id,
            dept_id: this.dept_id,
            ref_no: this.ref_no,
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.mts_no = "";
          this.origin_id = "";
          this.destination_id = "";
          this.dept_id = "";
          this.ref_no = ""; 
          const data = {
            mts_no: this.mts_no,
            origin_id: this.origin_id,
            destination_id: this.destination_id,
            dept_id: this.dept_id,
            ref_no: this.ref_no,
          }
          this.$emit("depot-search-query", data)
        },

        getCustomer(){

	    	axios.get(config.api_path+'/customer?page=1&limit=10000000')
	    	.then(response => {
	    		this.customerList = response.data; 
	    		this.customerList.data.unshift({
	    			customer_name: 'All',
	    			id: 'all'
	    		})
	    	})

	    }, 
      }
    }
</script>

<style scoped>

</style>
